@keyframes badgeShine {
  0% {
    transform: translateX(-50%) translateY(0px) rotateZ(-30deg);
  }
  70% {
    transform: translateX(100%) translateY(20px) rotateZ(-30deg);
  }
  100% {
    transform: translateX(100%) translateY(20px) rotateZ(-30deg);
  }
}
