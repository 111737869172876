@keyframes coin-in-cup-moving {
  0%,
  100% {
    transform: translate(var(--tw-translate-x), 0);
  }
  50% {
    transform: translate(var(--tw-translate-x), -80%);
  }
}

/* @keyframes coinAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes coinRaise {
  from {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) scale(1);
  }
  to {
    transform: translate(var(--tw-translate-x), calc(var(--tw-translate-y) - 20%)) scale(1.0875);
  }
}

@keyframes coinFall {
  from {
    transform: translate(var(--tw-translate-x), calc(var(--tw-translate-y) - 20%)) scale(1.0875);
  }
  to {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) scale(1);
  }
}

@keyframes coinLockedRaise {
  0% {
    transform: translate(var(--tw-translate-x), calc(var(--tw-translate-y) + 50%)) scale(1);
  }
  100% {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) scale(1);
  }
} */

@keyframes cup-raise {
  50% {
    transform: translate(var(--tw-translate-x), calc(var(--tw-translate-y) - 8%));
  }
  0%,
  100% {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y));
  }
}

@keyframes claim-cup {
  0% {
    transform: scale(1) translate(-50%, 60%);
  }
  100% {
    transform: scale(1.4) translate(-50%, 90%);
  }
}
