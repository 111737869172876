.top-product-card {
  max-width: 15rem;
}

.bottom-product-card {
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  background: linear-gradient(157.87deg, rgba(126, 125, 217, 0.4) 0%, rgba(20, 47, 117, 0.4) 100%);
}

.point-exchange-card {
  background-image: linear-gradient(to bottom, #30105c, #8000ff);
  border-radius: 8px;
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 600px) {
  .width-container-top-products {
    min-width: 146px;
  }
}

@media only screen and (min-width: 600px) {
  .width-container-top-products {
    min-width: 250px;
  }
}

/* product image thumbnail */
/* @media only screen and (max-width: 600px) {
    .product-image-size{
            min-width: 154px;
        }
    }

@media only screen and (min-width: 600px) {
        .product-image-size{
            min-width: 200px;
        }
} */

.card {
  background: linear-gradient(157.87deg, rgba(126, 125, 217, 0.4) 0%, rgba(20, 47, 117, 0.4) 100%);
  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.24),
    inset 1px 1px 2px rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(20px);
}

.card-transparent {
  background: linear-gradient(157.87deg, rgba(126, 125, 217, 0.4) 0%, rgba(20, 47, 117, 0.4) 100%);
  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.24),
    inset 1px 1px 2px rgba(255, 255, 255, 0.14);
}

.product-details-card {
  background: linear-gradient(157.87deg, rgba(126, 125, 217, 0.4) 0%, rgba(20, 47, 117, 0.4) 100%);
}

.accordion-button-style {
  background: linear-gradient(157.87deg, rgba(126, 125, 217, 0.4) 0%, rgba(20, 47, 117, 0.4) 100%);
}

.icon-color:active i {
  color: rgba(246, 189, 102, 1);
}

.icon-color:focus i {
  color: rgba(246, 189, 102, 1);
}

.icon-color:hover i {
  color: rgba(246, 189, 102, 1);
}

.active i {
  color: rgba(246, 189, 102, 1);
}

.semi-transparent-button {
  background-color: rgba(0, 0, 0, 0.3);
}

.ilovev-form input {
  background: #0a0013;
  border: 2px solid rgba(87, 85, 148, 0.669);
  border-radius: 8px;
}

.loader {
  border-top-color: #c1b6d4;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.slick-prev:before,
.slick-next:before {
  font-size: 25px !important;
}
