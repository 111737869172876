.text-product-card {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.bottom-product-card {
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  background: linear-gradient(157.87deg, rgba(126, 125, 217, 0.4) 0%, rgba(20, 47, 117, 0.4) 100%);
}
