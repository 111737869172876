.sprite-btn-gift {
  width: 48px;
  height: 48px;
  display: block;
  background: transparent url("/public/images/icon/fab-dailygift.gif") 0 0 no-repeat;
}

@keyframes btn-gift {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -528px 0;
  }
}
