.CoinAmount-container {
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 100% */
  position: relative;
  font-family: "Inter";
  width: max-content;
}
.CoinAmount-layer1 {
  position: relative;
  background: linear-gradient(359deg, #f4a011 0.4%, #c35e00 55.64%, #ffb015 57.91%, #e0850a 109.73%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 4;
}

.CoinAmount-layer2 {
  position: absolute;
  top: 6px;
  left: 0;
  color: #713f00;
  text-shadow: 0px 0px 16px #ffdc84;
  z-index: 1;
}
.CoinAmount-layer2a {
  position: absolute;
  top: 0px;
  left: 0;
  background-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 30px #ffdc84;
}

.CoinAmount-layer3 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 3;
  -webkit-text-stroke: 4px white;
}
