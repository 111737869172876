.mission-card__light-sweep {
  width: 80px;
  height: 250px;
  top: 0;
  left: 0;
  position: absolute;
  transform-origin: 50% 50%;
  animation: missionCardLightMoving 7600ms ease-in-out infinite;
}

.light-sweep--claimable {
  background: linear-gradient(90deg, rgba(134, 239, 172, 0) 0%, rgba(134, 239, 172, 0.5) 45%, rgba(134, 239, 172, 0.5) 55%, rgba(134, 239, 172, 0) 100%);
}

.light-sweep--in-progress {

}

.light-sweep--with-progress {
  background: linear-gradient(90deg, rgba(139, 92, 246, 0) 0%, rgba(139, 92, 246, 0.7) 45%, rgba(139, 92, 246, 0.7) 55%, rgba(139, 92, 246, 0) 100%);
}

.light-sweep--none-progress {
  background: linear-gradient(90deg, rgba(139, 92, 246, 0) 0%, rgba(139, 92, 246, 0.4) 45%, rgba(139, 92, 246, 0.4) 55%, rgba(139, 92, 246, 0) 100%);
}

@keyframes missionCardLightMoving {
  0%, 11%, 100% {
    transform: translateX(480px) translateY(20px) rotate(45deg);
  }
  52%, 63% {
    transform: translateX(-120%) translateY(-50%) rotate(45deg);
  }
}
