@keyframes coinScale {
  0% {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) scale(1);
  }
  50% {
    transform: translate(var(--tw-translate-x), calc(var(--tw-translate-y) - 0.8%)) scale(1.0875);
  }
  100% {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) scale(1);
  }
}

@keyframes coinAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes coinRaise {
  from {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) scale(1);
  }
  to {
    transform: translate(var(--tw-translate-x), calc(var(--tw-translate-y) - 20%)) scale(1.0875);
  }
}

@keyframes coinRaiseHigher {
  from {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) scale(1);
  }
  to {
    transform: translate(var(--tw-translate-x), calc(var(--tw-translate-y) - 40%)) scale(1.0875);
  }
}

@keyframes coinFall {
  from {
    transform: translate(var(--tw-translate-x), calc(var(--tw-translate-y) - 20%)) scale(1.0875);
  }
  to {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) scale(1);
  }
}

@keyframes coinFallHigher {
  from {
    transform: translate(var(--tw-translate-x), calc(var(--tw-translate-y) - 40%)) scale(1.0875);
  }
  to {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) scale(1);
  }
}

@keyframes coinLockedRaise {
  0% {
    transform: translate(var(--tw-translate-x), calc(var(--tw-translate-y) + 50%)) scale(1);
  }
  100% {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) scale(1);
  }
}
