.change-password-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
}

.change-password-form {
  background: linear-gradient(157.87deg, rgba(130, 94, 192, 0.4) 0%, rgba(20, 47, 117, 0.4) 100%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.change-password-form input {
  height: 48px;
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  background: #170b37;
  border: 1px solid rgba(210, 193, 255, 0.3);
}

/* .change-password-form button {
  height: 65px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 12px;
} */
