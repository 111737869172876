.StrokeText-container {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 100% */
  position: relative;
  font-family: "Inter";
  width: max-content;
}
.StrokeText-layer1 {
  position: relative;
  color: black;
  z-index: 4;
}

.StrokeText-layer3 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 3;
  -webkit-text-stroke: 3px white;
}
