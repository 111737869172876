@import "~@fontsource-variable/inter/wght.css";
@import "~@fontsource-variable/noto-sans-thai/wght.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @media screen and (max-width: 768px) {
    button,a {
      @apply no-tap-highlight;
    }
  }
  h1 {
    @apply text-4xl font-bold;
  }

  h2 {
    @apply text-3xl font-semibold;
  }

  h3 {
    @apply text-2xl font-semibold;
  }

  h4 {
    @apply text-2xl font-bold;
  }

  body {
    @apply bg-primary-800 bg-starry bg-contain bg-fixed bg-repeat lg:bg-auto;
  }
}

@layer components {
  .btn-blue {
    @apply relative w-full rounded-lg border-0 bg-gradient-to-r from-blue-dark to-blue-light py-4 px-5 text-center font-extrabold text-white;
  }

  .btn-orange {
    @apply relative w-full rounded-lg border-0 bg-gradient-to-r from-orange-dark to-orange-light py-4 px-5 text-center font-extrabold text-white;
  }

  .btn-purple {
    @apply relative w-full rounded-lg border-0 bg-gradient-to-r from-blue-dark to-purple-light py-4 px-5 text-center font-extrabold text-white;
  }

  .tertiary-button {
    @apply relative w-full rounded-lg border-0 bg-gradient-to-r from-orange-dark to-orange-light py-4 px-6 text-center text-base font-bold leading-4 text-white;
  }

  .tertiary-button-small {
    @apply relative w-full rounded border-0 bg-gradient-to-r from-orange-dark to-orange-light py-2 px-3 text-center text-sm font-bold leading-4 text-white;
  }

  .text-body-small-bold {
    @apply text-sm font-bold leading-[18px] tracking-normal;
  }

  .text-body-small {
    @apply text-sm font-normal leading-4 tracking-normal;
  }

  .text-small {
    @apply text-xs font-normal leading-3 tracking-normal;
  }

  .text-link {
    @apply text-sm font-normal leading-5 tracking-normal underline;
  }

  .text-secondary {
    color: #b9a3f1;
  }

  .bg-token-gradient {
    @apply bg-gradient-to-b from-custom-purple to-primary-800;
  }

  .bg-lvl-1-card-gradient {
    @apply bg-gradient-to-b from-card-gradient-left to-card-gradient-right;
  }

  .radio-tick {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url("/public/images/icon/radio-uncheck.svg");
    background-size: cover;
  }

  .radio-tick:checked {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url("/public/images/icon/radio-checked.svg");
    background-size: cover;
  }

  [type="radio"]:checked {
    color: #6840d5;
  }

  form input[type="password"]:focus {
    border: 1px solid rgba(185, 163, 241, 1);
  }

  button:disabled {
    opacity: 0.75;
  }

  .login-form input:-webkit-autofill,
  .login-form input:-webkit-autofill:hover,
  .login-form input:-webkit-autofill:focus,
  .login-form input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgba(30, 14, 74, 1) inset !important;
    border: 2px solid rgba(98, 98, 109, 0.9);
  }

  form input:-webkit-autofill,
  form input:-webkit-autofill:hover,
  form input:-webkit-autofill:focus,
  form input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #000000 inset !important;
  }

  form input:-webkit-autofill {
    -webkit-text-fill-color: rgb(255, 255, 255) !important;
  }

  .btn-gradient-outlined {
    transition: background 0.3s;
    background:
      linear-gradient(#1e0e4a, #1e0e4a) padding-box,
      linear-gradient(85deg, #0030ac -1.07%, #9822f4 99.12%) border-box;
    border: 4px solid transparent;
    border-image: linear-gradient(85deg, #0030ac -1.07%, #9822f4 99.12%);
    border-image-slice: 1;
  }

  .btn-gradient-outlined:active {
    background:
      linear-gradient(#160547, #0f042f) padding-box,
      linear-gradient(85deg, #0030ac -1.07%, #9822f4 99.12%) border-box;
  }

  .avatar-gradient {
    background: linear-gradient(to right, #9822f4, #0030ac);
    animation: rotate 0.8s linear infinite;
  }
}

@layer utilities {
  .no-tap-highlight {
    @apply select-none focus:!outline-none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .hide-scroll-bar::-webkit-scrollbar {
    display: none;
  }

  .font-feature-tnum {
    font-feature-settings: "tnum";
    -moz-font-feature-settings: "tnum";
    -webkit-font-feature-settings: "tnum";
  }

  .line-clamp-1 {
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .line-clamp-2 {
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  .scrollbar-primary {
    @apply scrollbar-thin scrollbar-track-primary-800 scrollbar-thumb-primary-500;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.change-password-form input {
  @apply h-12 rounded-lg p-4 text-sm font-normal bg-[#170b37] border border-[rgba(210,193,255,0.3)];
}
