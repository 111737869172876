.card {
  background: linear-gradient(157.87deg, rgba(126, 125, 217, 0.4) 0%, rgba(20, 47, 117, 0.4) 100%);
  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.24),
    inset 1px 1px 2px rgba(255, 255, 255, 0.14);
  /*backdrop-filter: blur(20px);*/
}

.card-transparent {
  background: linear-gradient(157.87deg, rgba(126, 125, 217, 0.4) 0%, rgba(20, 47, 117, 0.4) 100%);
  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.24),
    inset 1px 1px 2px rgba(255, 255, 255, 0.14);
}


.slick-prev:before,
.slick-next:before {
  font-size: 25px !important;
}

.slick-list > div {
  margin-left: 0;
}


.slick-prev:before {
  font-family: Arial;
  content: "<";
  color: rgba(27, 33, 46);
  font-weight: bold;
  margin-left: 10px;
}

.slick-next:before {
  font-family: Arial;
  content: ">";
  color: rgba(27, 33, 46);
  font-weight: 700;
  margin-left: -10px;
}

.slick-slide > div {
  line-height: 0;
}


.slick-custom-thumb {
  display: flex !important;
  position: unset;
  overflow-x: auto;
  column-gap: .25rem;
  padding: .5rem .25rem .25rem .25rem;
}

.slick-custom-thumb::-webkit-scrollbar {
  display: none;
}

.slick-custom-thumb li {
  width: 84px;
  height: 84px;
  display: block;
  margin: 0;
  border-width: 2px;
  border-color: transparent;
  position: relative;
  transition: border 0.3s ease;
  border-radius: 8px;
  flex-shrink: 0;
}

.slick-custom-thumb li button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1px;
  border-radius: 8px;
}

.slick-custom-thumb li button::before {
  display: none;
}

.slick-custom-thumb .slick-active {
  border-color: currentColor;
}

.slick-custom-thumb img {
  border-radius: 8px;
}
