@keyframes slow-shining {
  0%,
  100% {
    opacity: 1;
  }
  15% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  85% {
    opacity: 1;
  }
}
@keyframes slow-shining-reverse {
  0%,
  100% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  85% {
    opacity: 0;
  }
}

@keyframes rotate-fw-bw {
  0%,
  95%,
  100% {
    transform: rotate(0);
  }
  40% {
    transform: rotate(-110deg);
  }
  45%,
  50% {
    transform: rotate(-120deg);
  }
  90% {
    transform: rotate(-10deg);
  }
}

@keyframes spreadout-assemble {
  0%,
  85%,
  100% {
    opacity: 0.8;
    scale: 1;
  }
  35%,
  50% {
    opacity: 0;
    scale: 2;
  }
}

@keyframes assemble-spreadout {
  0%,
  85%,
  100% {
    opacity: 0;
    scale: 2;
  }

  35%,
  50% {
    opacity: 0.8;
    scale: 1;
  }
}

@keyframes moving-shine {
  0%,
  80%,
  100% {
    transform: translateY(0);
  }
  30%,
  50% {
    transform: translateY(200%);
  }
}
