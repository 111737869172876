.gift-box__background {
  animation: gift-box-bg-shake 1.4s ease-out infinite;
}

.gift-box__foreground {
  animation: gift-box-fg-slide 1.4s ease-out infinite;
}

@keyframes gift-box-bg-shake {
  0% {
    transform: rotateZ(4deg);
  }
  14% {
    transform: rotateZ(-4deg);
  }
  28% {
    transform: rotateZ(4deg);
  }
  42% {
    transform: rotateZ(-4deg);
  }
  56% {
    transform: rotateZ(0deg);
  }
}

@keyframes gift-box-fg-slide {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
}
