.background-opacity {
  opacity: 0.5;
}

.text-ignore-opacity {
  color: transparent;
}

.text-shadow {
  text-shadow: 0 0 3px #fff;
}

.difference {
  mix-blend-mode: difference;
}
