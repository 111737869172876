@keyframes coin-shining {
  50% {
    opacity: 0.5;
  }
}

@keyframes left-coin-moving {
  50% {
    transform: translate(0, -5%) scale(0.5) rotate(-15deg);
  }
}
@keyframes right-coin-moving {
  50% {
    transform: translate(0, -5%) scale(0.5) rotate(12deg);
  }
}
@keyframes mid-coin-moving {
  50% {
    transform: translate(-50%, -10%);
  }
}

@keyframes raising-locked-chest {
  0% {
    transform: translateY(267px);
  }
  100% {
    transform: translateY(0);
  }
}

.open-chest-container-disappear {
  transition: opacity 1.5s linear 1s;
}
.open-chest-components-moving-down {
  transition: transform 1.5s linear 1s;
}

@keyframes claim-button-raising {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes show-available-chest {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
