.claim-button__light-sweep {
  background: linear-gradient(90deg, rgba(214, 255, 144, 0.00) 0%, rgba(214, 255, 144, 0.40) 45%, rgba(214, 255, 144, 0.40) 55%, rgba(214, 255, 144, 0.00) 100%);
  top: -50%;
  left: -50%;
  animation: lightMoving 1.5s infinite;
  transform: rotate(40deg);
  position: absolute;
  width: 24px;
  height: 96px;
  pointer-events: none;
}


@keyframes lightMoving {
  100% {
    transform: translateX(200px) translateY(10px) rotate(40deg);
  }
}
